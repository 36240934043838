<template>
  <b-card
    no-body
    class="card-developer-meetup"
  >
    <b-card-body>
      <rc-overlay
        :show="processing"
        variant="white"
        class="h-100"
      >
        <div v-if="meeting.service">

          <!-- meeting header -->
          <div class="meetup-header d-flex align-items-center mb-1">
            <div class="meetup-day">
              <h6 class="mb-0">
                {{ $t(`shared.days.short.${day.day()}`).toUpperCase() }}
              </h6>
              <h3 class="mb-0">
                {{ day.format('D') }}
              </h3>
            </div>
            <div class="my-auto">
              <b-card-title
                class="mb-25"
              >
                {{ meeting.service.name }}
              </b-card-title>
            </div>
          </div>
          <!--/ meeting header -->

          <!-- media -->
          <b-media
            v-for="(media, key) in mediaData"
            v-show="media.title"
            :key="`${media.avatar}-${key}`"
            no-body
          >
            <b-media-aside
              class="mr-1"
            >
              <b-avatar
                rounded
                variant="light-primary"
                size="34"
              >
                <feather-icon
                  :icon="media.avatar"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-0">
                {{ media.title }}
              </h6>
              <small>{{ media.subtitle }}</small>
            </b-media-body>
          </b-media>
        </div>
      </rc-overlay>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardTitle, BMedia, BMediaAside, BAvatar, VBTooltip, BMediaBody,
} from 'bootstrap-vue'
import dayjs from 'dayjs'
import { capitalizeFirstLetter } from '@/helpers/helpers'
import RcOverlay from '@/layouts/components/rc/RcOverlay'
import role from '@/auth/role'
/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    RcOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    meeting: {
      type: Object,
      required: true,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    navigation: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    me() {
      return this.$store.getters['auth/getUser']
    },
    day() {
      return dayjs(this.meeting.startAt)
    },
    dayTo() {
      return dayjs(this.meeting.endAt)
    },
    dayString() {
      return capitalizeFirstLetter(this.$t(`shared.days.long.${this.day.day()}`))
    },
    month() {
      return this.$t(`shared.months-for-full-date.${this.day.month()}`)
    },
    paymentStatus() {
      const paymentStatus = { paid: 0, total: 0 }

      this.meeting.calendarEventCustomerObjects.forEach(calendarEventCustomer => {
        paymentStatus.total += calendarEventCustomer.settlement.amount
        if (calendarEventCustomer.settlement.paid) {
          paymentStatus.paid += calendarEventCustomer.settlement.amount
        }
      })

      return paymentStatus
    },
    mediaData() {
      let paymentStatus = this.$t('meeting.attendees.status-partially-paid')
      if (this.paymentStatus.paid === this.paymentStatus.total) {
        paymentStatus = this.$t('meeting.attendees.status-paid')
      } else if (this.paymentStatus.paid === 0) {
        paymentStatus = this.$t('meeting.attendees.status-not-paid')
      }

      const mediaList = [
        {
          avatar: 'CalendarIcon',
          title: `${this.dayString}, ${this.day.date()} ${this.month} ${this.day.year()}`,
          subtitle: this.$t('shared.from-to', { from: this.day.format('HH:mm'), to: this.dayTo.format('HH:mm') }),
        },
        {
          avatar: 'UserIcon',
          title: `${this.meeting.calendar.owner.lastName} ${this.meeting.calendar.owner.firstName}`,
          subtitle: this.$t('shared.educator'),
        },
      ]

      if (this.me.roles.includes(role.guardian)) {
        mediaList.push(
          {
            avatar: this.meeting.calendarEventCustomers && this.meeting.calendarEventCustomers.length === 1 ? 'UserIcon' : 'UsersIcon',
            title: Object.values(this.meeting.calendarEventCustomerObjects).map(c => `${c.customer.lastName} ${c.customer.firstName}`).join(', '),
            subtitle: this.$tc('shared.plural.attendees-title', this.meeting.calendarEventCustomers.length),
          },
        )
      }

      mediaList.push(
        {
          avatar: 'MapPinIcon',
          title: this.meeting.location ? this.$t(`shared.service-location.${this.meeting.location}`) : null,
          subtitle: this.meeting.locationDetails,
        },
      )
      mediaList.push(
        {
          avatar: 'DollarSignIcon',
          title: this.paymentStatus.total ? paymentStatus : null,
          subtitle: this.$t('meeting.attendees.payment-status'),
        },
      )

      return mediaList
    },
  },
}
</script>
